<template>
  <v-container class="pa-0" fluid>
    <router-view></router-view>
  </v-container>
</template>

<script>

export default {
  name: 'MoU-Page',
};
</script>
